import React, { useEffect } from "react";
import styles from "./Bigcard.module.css";
const Bigcard = (props) => {
  useEffect(() => {
    if (props.hideBG) {
      props.hideBG();
    }
  }, [props.hideBG]);

  return (
    <>
      <div className={`${styles.card}`}>
        <img
          className={styles.bigImg}
          src={props.brandImage}
          alt="ARVRMR_CLIENTS_COMPANY_IMAGES"
        />
        <img
          className={styles.smallImg}
          src={props.logo}
          alt="ARVRMR_CLIENTS_LOGO"
        />
      </div>
    </>
  );
};

export default Bigcard;
