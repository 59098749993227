import React, { useState, useRef, useEffect } from "react";
import styles from "./Servicescard.module.css";
import TrackVisibility from "react-on-screen";
import IsVisible from "react-is-visible";
import { useSpring, animated } from "react-spring";
import Tilt from "react-parallax-tilt";
/* import { motion, useMotionValue, useSpring, useTransform } from "framer-motion"; */
const Servicescard = ({ classes, head, list, setOpt }) => {
  const trans = (x, y, s) => {
    return `perspective(${800}px) rotateX(${x * 10}deg) rotateY(${
      y * 10
    }deg)  scale(${s})`;
  };
  const calc = (x, y) => {
    const button = document.querySelector(".shiny");
    button.addEventListener("mousemove", (e) => {
      const { x, y } = button.getBoundingClientRect();
      button.style.setProperty("--x", e.clientX - x);
      button.style.setProperty("--y", e.clientY - y);
    });
    var winHalfWidth = window.innerWidth / 2;
    var winHalfHeight = window.innerHeight / 2;
    return [
      -(y - winHalfWidth) / winHalfWidth,
      (x - winHalfHeight) / winHalfHeight,
      1.1,
    ];
  };
  const ref = useRef(null);

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  useEffect(() => {
    if (setOpt) {
      setOpt();
    }
  }, [setOpt]);

  /* const x = useMotionValue(450);
  const y = useMotionValue(250);

  const centeredX = useSpring(
    useTransform(x, (n) => n - 600),
    {
      bounce: 1,
      stiffness: 800,
      damping: 80,
    }
  );
  const centeredY = useSpring(
    useTransform(y, (n) => n - 600),
    {
      bounce: 1,
      stiffness: 800,
      damping: 80,
    }
  );
  const hoverState = useMotionValue(0);
  const hoverOpacity = useSpring(hoverState);
  const rotateX = useSpring(useTransform(y, [0, 300], [-5, 5]), {
    bounce: 1,
    stiffness: 800,
    damping: 80,
  });
  const rotateY = useSpring(useTransform(x, [0, 700], [15, -15]), {
    bounce: 1,
    stiffness: 800,
    damping: 80,
  });
  function handleMouse(event) {
    const rect = event.currentTarget.getBoundingClientRect();

    hoverState.set(1);
    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top);
  }
  function mouseLeave(event) {
    const card = document.getElementById("animDiv");
    card.style.transform = "rotateY(0deg) rotateX(0deg)";
    hoverState.set(0);
    x.set(250);
    y.set(150);
  }
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
 */
  return (
    <>
      {/*   <Card
        cursorPointer={true}
        shineStrength={0.75}
        style={{
          height: "auto",
          cursor: "pointer",
          borderRadius: "8px",
          background: "transparent",
        }}
      > */}
      {/*    <animated.div
        ref={ref}
        className={`${styles.animateDiv} shiny`}
        onMouseMove={(e) => {
          const { clientX: x, clientY: y } = e;
          return set({ xys: calc(x, y) });
        }}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}
      > */}
      <Tilt
        id="servicard"
        scale={1.07}
        tiltMaxAngleY={8}
        tiltMaxAngleX={7}
        transitionSpeed={2500}
        className={`${styles.card} ${styles.animateDiv}  ${classes}`}
        glareEnable={true}
        glareBorderRadius="10px"
        glareMaxOpacity={0.4}
        glareColor="lightblue"
        glarePosition="all"
      >
        {/*  <div className={`${styles.card} ${styles.animateDiv}  ${classes}`}> */}
        <h1>{head}</h1>
        {list.map((service) => (
          <li key={service.id}>{service.content}</li>
        ))}
        {/*  </div> */}
      </Tilt>
      {/*  </animated.div> */}

      {/*   </Card> */}
    </>
  );
};

export default Servicescard;
