import React from "react";
import instalogo from "../../images/SocialIcons/instagram.png";
import facebooklogo from "../../images/SocialIcons/facebook.png";
import linkedinogo from "../../images/SocialIcons/linkedin-in.png";
import twitterlogo from "../../images/SocialIcons/twitter.png";
import ScrollToTop from "react-scroll-to-top";

import styles from "./Footer.module.css";
const Footer = () => {
  return (
    <>
      <div className={styles.footerwrapper}>
        <ScrollToTop
          id="scrollToTop"
          smooth
          viewBox="0 0 10 10"
          svgPath="M33.941,31.975,60.015,51.1a2.778,2.778,0,0,1,0,4.767l-4.333,3.178a5.822,5.822,0,0,1-6.5,0L30.681,45.5,12.2,59.06a5.822,5.822,0,0,1-6.5,0L1.348,55.882a2.778,2.778,0,0,1,0-4.767L27.422,31.989A5.827,5.827,0,0,1,33.941,31.975Zm-6.519-27L1.348,24.1a2.778,2.778,0,0,0,0,4.767l4.333,3.178a5.822,5.822,0,0,0,6.5,0L30.662,18.489,49.144,32.046a5.822,5.822,0,0,0,6.5,0l4.333-3.178a2.778,2.778,0,0,0,0-4.767L33.9,4.975a5.79,5.79,0,0,0-6.48,0Z"
        />

        <div className={styles.socialIcons}>
          <a href="https://www.instagram.com/vrarmrofficial/">
            <img
              src={instalogo}
              alt="VRARMR_instagram..."
              className={styles.icon}
            />
          </a>
          <a href="https://www.facebook.com/vrarmr/">
            <img
              src={facebooklogo}
              alt="VRARMR_facebook..."
              className={styles.icon}
            />
          </a>
          <a href="https://www.linkedin.com/company/vrarmr/mycompany/">
            <img
              src={linkedinogo}
              alt="VRARMR_linkedin..."
              className={styles.icon}
            />
          </a>
          <a href="https://twitter.com/vrarmrofficial">
            <img
              src={twitterlogo}
              alt="VRARMR_twitter..."
              className={styles.icon}
            />
          </a>
        </div>
        {/*   <div className={styles.content}>
          <p className={styles.yearReserved}>
            ©2022 VR AR MR. All rights reserved.
          </p>
          <p>Privacy Policy</p>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
