import React, { useState, useRef, useEffect } from "react";
import styles from "./Contact.module.css";
import { IoCheckmarkCircle, IoMailOutline } from "react-icons/io5";
import Formcard from "../../SmallComponent/Contactform/Formcard";
import TrackVisibility from "react-on-screen";
import staticMail from "../../images/emailgif.json";
import bg from "../../images/Ending_Page.jpg";

import axios from "axios";

const Contact = (props) => {
  const form = useRef();
  const [sendMail, setSendMail] = useState(false);
  const [mail, setMail] = useState("");
  const getUserMail = (e) => {
    setMail(e.target.value);
    console.log(mail);
  };

  function setAnimationOverSecondBtn() {
    let animBg = document.getElementsByClassName("bg");
    let animBgPos = document.getElementsByClassName("bg")[0].style.position;
    if (animBgPos == "absolute") {
      /* set angle/direction of linear gradient */
      animBg[0].classList.remove("bgsetaniamtion");
      animBg[1].classList.remove("bgsetaniamtion");
      animBg[2].classList.remove("bgsetaniamtion");

      /* set position */
      animBg[0].style.position = "fixed";
      animBg[1].style.position = "fixed";
      animBg[2].style.position = "fixed";
    }
  }
  const sendEmailHandler = (e) => {
    e.preventDefault();
    document.getElementById("subscribe_email").style.color = "lightgrey";
    if (mail != "") {
      console.log("hello");
      setSendMail(true);
    }
    /*     axios
      .post(
        "https://sheet.best/api/sheets/89471dca-c5d6-4acf-a768-8c95b2c4d530",
        data
      )
      .then((response) => {
        console.log(response);
        document.getElementById("subscribe_email").style.color = "green";
        setTimeout(() => {
          setMail("");
          setSendMail(false);
          document.getElementById("subscribe_email").style.color = "#A6AED8";
        }, 1000);
      }); */
    fetch(
      "https://script.google.com/macros/s/AKfycbyUqTpxi5oA9T3gh0OehMTHS3HLO6ojTN38QOczRPUZjW13FuOOTltI7oAWQGkSb7f3fA/exec",
      {
        method: "POST",
        body: new FormData(form.current),
      }
    )
      .then((res) => {
        console.log("sucessfuly send", res);

        setTimeout(() => {
          setMail("");
          setSendMail(false);
          document.getElementById("subscribe_email").style.color = "#a6aed8";
        }, 1000);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    props.setBg();
  }, [props.setBg]);
  /*
  console.log(window.scrollY); */
  return (
    <>
      <div id="contact" className={styles.contactpage}>
        {/*  <img className={styles.bgimg} src={bg} alt="...bg" /> */}
        <div className={styles.heading_input_conatiner}>
          <TrackVisibility>
            {({ isVisible }) => (
              <div className={styles.heading}>
                <h1 className={isVisible ? "textAnim" : ""}>
                  Subscribe to illuminate with updates
                </h1>
              </div>
            )}
          </TrackVisibility>
          <div className={styles.inputContainer}>
            <form
              className={`${styles.inputwrapper} input-group text-center mb-3`}
              ref={form}
              onSubmit={sendEmailHandler}
              needs-validation="true"
            >
              <input
                name="clientEmail"
                id="subscribe_email"
                autoComplete="off"
                type="email"
                style={{ color: "#A6AED8" }}
                value={mail}
                className={`${styles.inputfield} form-control`}
                placeholder="Enter your e-mail id"
                aria-label="Enter your e-mail id"
                aria-describedby="basic-addon2"
                onChange={getUserMail}
                required
              />
              <button
                type="submit"
                className="input-group-text mail_box"
                id="basic-addon2"
              >
                {sendMail && (
                  <div className="wrapper">
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}
                {!sendMail && <IoMailOutline className={styles.mail} />}
              </button>
            </form>
          </div>
        </div>
        <div>
          <TrackVisibility partialVisibility>
            {({ isVisible }) => (
              <Formcard
                setAnimbg={isVisible ? setAnimationOverSecondBtn : ""}
              />
            )}
          </TrackVisibility>
        </div>
      </div>
    </>
  );
};

export default Contact;
