import React, { useState, useEffect } from "react";
import Careercard from "../../SmallComponent/CareerCard/Careercard";
import styles from "./Career.module.css";
import TrackVisibility from "react-on-screen";
import IsVisible from "react-is-visible";
import cardimage2 from "../../images/Packagecard2.png";
const Career = (props) => {
  const [active, setActive] = useState("");
  //console.log(getId);
  //console.log(active);
  function clickCard(event) {
    const getId = event.currentTarget.id;
    //console.log(getId);
    //console.log(active);
    if (active === getId) {
      setActive("");
    } else {
      //console.log(active);
      setActive(getId);
    }
  }
  function mouseHover(event) {
    const getId = event.currentTarget.id;
    setActive(getId);
  }
  function mouseLeave(event) {
    setActive("");
  }

  function reduceOpac() {
    let BG = document.getElementById("cntBG");
    if (BG) {
      BG.style.opacity = "0.5";
      /* videoReplaceBG.classList.remove("addBgColor"); */
    }
  }
  function removeFormBg() {
    let videoReplaceBG = document.getElementById("cntBG");
    if (videoReplaceBG) {
      videoReplaceBG.style.opacity = "0.5";
    }
    if (window.innerWidth < 768) {
      if (videoReplaceBG) {
        videoReplaceBG.classList.remove("addFormBgImage");
        videoReplaceBG.classList.add("addBgColor");
      }
    } else {
      /*  if (videoReplaceBG) {
        videoReplaceBG.classList.add("addFormBgImage");
      } */
    }
  }
  useEffect(() => {
    props.remvBg();
  }, [props.remvBg]);

  return (
    <>
      <div id="packages" className={`${styles.Careercard}`}>
        <IsVisible>
          {(isVisible) => (
            <div className={styles.heading}>
              <h1 className={isVisible ? "textAnim text-left" : "text-left"}>
                To bring your immersive tech ideas to fruition, we can be your
                partner in success in 3 ways.
              </h1>
            </div>
          )}
        </IsVisible>
        <div className={styles.cardWrapper}>
          <Careercard
            setCurrentActiveCard={active}
            check={window.innerWidth < 768 ? clickCard : mouseHover}
            leave={mouseLeave}
            setId={"card1"}
            imgs={cardimage2}
            content={
              "As a technology partner, to solve your business problems we develop and deploy technology solutions to help you succeed and lap your competitors with efficiency and innovation."
            }
            imgtext={"Technology Partner"}
          />
          <TrackVisibility>
            {({ isVisible }) => (
              <Careercard
                setCurrentActiveCard={active}
                check={window.innerWidth < 768 ? clickCard : mouseHover}
                leave={mouseLeave}
                setId={"card2"}
                imgs={cardimage2}
                content={
                  "Commission us for AR/VR/ Metaverse Conceptualisation, Experience Design, Development and Production on your specific projects."
                }
                imgtext={"Campaign Specific"}
                setOpt={isVisible ? removeFormBg : ""}
              />
            )}
          </TrackVisibility>

          <Careercard
            setCurrentActiveCard={active}
            check={window.innerWidth < 768 ? clickCard : mouseHover}
            leave={mouseLeave}
            setId={"card3"}
            imgs={cardimage2}
            content={
              "This package will allow you to put all your worries for 12 months on us. Our teams will plan your experiences in advance and execute them to give you the greatest possible success."
            }
            imgtext={"Retainer Plan"}
          />
        </div>
      </div>
    </>
  );
};

export default Career;
