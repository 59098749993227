import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Navbar from "./components/Navbar/Navbar";
import Bodytext from "./components/About/Bodytext/Bodytext";
import Cardpage from "./components/About/Cardpage/Cardpage";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import Services from "./components/Services/Services";
import Career from "./components/Career/Career";
import Contact from "./components/ContactUs/Contact";
import Footer from "./components/Footer/Footer";
import Video from "./components/compVideo/Video";
import TrackVisibility from "react-on-screen";
import Landing from "./components/landingPage/Landing";
import CmpCareer from "./companyCareer/CmpCareer";

const App = () => {
  const [videoVs, setVideoVs] = useState("hello");
  useEffect(() => {
    const vide = document.getElementsByClassName("vbs")[0];
    if (vide) {
      setVideoVs("1");
    } else {
      setVideoVs("0");
    }
  }, [videoVs]);

  function setBgAnimation() {
    let anim1 = document.getElementsByClassName("bg")[0];
    let anim2 = document.getElementsByClassName("bg")[1];
    let anim3 = document.getElementsByClassName("bg")[2];
    if (window.innerWidth < 768) {
      /* set position of animations */
      anim2.setAttribute("style", "position:absolute;");
      anim1.setAttribute("style", "position:absolute;");
      anim3.setAttribute("style", "position:absolute;");

      /* set angle/direction of linear gradient */
      anim1.classList.add("bgsetaniamtion");
      anim2.classList.add("bgsetaniamtion");
      anim3.classList.add("bgsetaniamtion");
    }
  }
  let BG = document.getElementById("cntBG");
  function addLogoSecBg() {
    if (BG) {
      BG.style.opacity = "1";
      BG.classList.add("addLogoSecImage");
      BG.classList.remove("addFormBgImage");
      //console.log("add logo section");
      /* videoReplaceBG.classList.remove("addBgColor"); */
    }
  }

  function removeLogoSecBg() {
    let videoReplaceBG = document.getElementById("cntBG");
    if (videoReplaceBG) {
      videoReplaceBG.classList.remove("addLogoSecImage");
      videoReplaceBG.classList.add("addBgColor");
      videoReplaceBG.classList.remove("addFormBgImage");
    }
  }

  let videoRepBG = document.getElementById("cntBG");
  function addFormBg() {
    if (videoRepBG) {
      videoRepBG.style.opacity = "1";
      videoRepBG.classList.add("addFormBgImage");
      videoRepBG.classList.remove("addLogoSecImage");
    }
  }
  function addBg1() {
    console.log("add for nothing,thanks for visit");
  }
  function removeFormBg() {
    let videoReplaceBG = document.getElementById("cntBG");
    if (videoReplaceBG) {
      videoReplaceBG.classList.remove("addFormBgImage");
      videoReplaceBG.classList.add("addBgColor");
      videoReplaceBG.classList.remove("addLogoSecImage");
    }
    //console.log("remove", document.documentElement.scrollTop);
  }
  function removeBg1() {
    console.log("remove1");
  }
  function bgNormal() {
    let videoRepBGCaseStudies = document.getElementById("cntBG");
    if (videoRepBGCaseStudies) {
      //console.log("case studies secxtion");
      videoRepBGCaseStudies.classList.add("addBgColor");
      videoRepBGCaseStudies.classList.remove("addFormBgImage");
      videoRepBGCaseStudies.classList.remove("addLogoSecImage");
    }
  }
  function bgCheck() {
    console.log("hello,thanks for visit");
  }

  return (
    <>
      <Landing />
      <div id="careerPage" style={{ display: "none", overflow: "hidden" }}>
        <CmpCareer />
      </div>
      <div id="main_component" className="video_container_wrapper">
        <TrackVisibility>
          {({ isVisible }) => (
            <div className={isVisible ? "vbs" : ""}>
              <Video />
            </div>
          )}
        </TrackVisibility>
        <div className="main_body_container">
          {/*    <div className="bgsetaniamtion"></div> */}
          <div className="bg"></div>
          <div className="bg bg2"></div>
          <div className="bg bg3"></div>
          <Navbar />
          <TrackVisibility partialVisibility>
            {({ isVisible }) => (
              <Bodytext setBgAnim={isVisible ? setBgAnimation : addBg1} />
            )}
          </TrackVisibility>
          <TrackVisibility partialVisibility>
            {({ isVisible }) => (
              <Cardpage setBg={isVisible ? addLogoSecBg : addBg1} />
            )}
          </TrackVisibility>
          <TrackVisibility>
            {({ isVisible }) => (
              <Services remvBg={isVisible ? removeLogoSecBg : removeBg1} />
            )}
          </TrackVisibility>
          <TrackVisibility partialVisibility>
            {({ isVisible }) => (
              <CaseStudies setBgNormal={isVisible ? bgNormal : bgCheck} />
            )}
          </TrackVisibility>
          <TrackVisibility>
            {({ isVisible }) => (
              <Career remvBg={isVisible ? removeFormBg : removeBg1} />
            )}
          </TrackVisibility>
          <TrackVisibility partialVisibility>
            {({ isVisible }) => (
              <Contact setBg={isVisible ? addFormBg : addBg1} />
            )}
          </TrackVisibility>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;
