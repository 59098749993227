import React, { useEffect } from "react";
import styles from "./Bodytext.module.css";
import IsVisible from "react-is-visible";

const Bodytext = (props) => {
  useEffect(() => {
    props.setBgAnim();
  }, [props.setBgAnim]);

  return (
    <>
      <IsVisible>
        {(isVisible) => (
          <div ref={props.reg} id="about" className={styles.mainText}>
            <div className={styles.Textwrapper}>
              <h1 className={isVisible ? "textAnim" : ""}>
                Over the years we’ve achieved world-class creative production on
                a global scale. We’re positively provoking growth for Fortune
                500 and Global 2000 companies across key industries.
              </h1>
            </div>
          </div>
        )}
      </IsVisible>
    </>
  );
};

export default Bodytext;
