import React, { useState, useEffect } from "react";
import bgImg from "../images/cmpcareer/Background.jpg";
import styles from "./CmpCareer.module.css";
import axios from "axios";
import { RxCaretLeft } from "react-icons/rx";
import swal from "sweetalert";

const CmpCareer = () => {
  //window.window.myIframe;
  var file;
  const [pdf, setPdf] = useState();
  const [fileNAme, setFileNAme] = useState("");
  function backToMain() {
    let careerPage = document.getElementById("careerPage");
    careerPage.style.display = "none";

    let mainPage = document.getElementById("main_component");
    mainPage.style.display = "block";
    let videomain = document.getElementById("cmpVid");
    videomain.classList.remove("d-none");
    videomain.style.display = "block";
    videomain.classList.add("vsbility");
    videomain.play();
    videomain.setAttribute("playsInline", "");
    videomain.setAttribute("muted", false);
    videomain.setAttribute("autoplay", "true");
    let anim1 = document.getElementsByClassName("bg")[0];
    let anim2 = document.getElementsByClassName("bg")[1];
    let anim3 = document.getElementsByClassName("bg")[2];
    if (window.innerWidth < 768) {
      /* set position of animations */
      anim2.setAttribute("style", "position:absolute;");
      anim1.setAttribute("style", "position:absolute;");
      anim3.setAttribute("style", "position:absolute;");

      /* set angle/direction of linear gradient */
      anim1.classList.add("bgsetaniamtion");
      anim2.classList.add("bgsetaniamtion");
      anim3.classList.add("bgsetaniamtion");
    }
    let curCircle = document.getElementById("cursor");
    curCircle.style.display = "none";
    let curBorder = document.getElementById("cursor-border");
    curBorder.style.display = "none";
    document.addEventListener("mousemove", (e) => {
      if (window.innerWidth > 768) {
        curCircle.style.display = "block";
        curBorder.style.display = "block";
      }
    });
  }

  function getPdf(e) {
    console.log(e.target.files[0]);
    console.log(e.target.value);
    setFileNAme(e.target.files[0].name);
  }
  function callIframeFunction() {
    if (fileNAme) {
      document.getElementById("aplyBtn").setAttribute("disable", true);
      console.log(window.myIframe);
      window.myIframe.contentWindow.myFunction();
      window.myIframe.style.display = "none";
      setFileNAme("");
      //show loader

      swal({
        title: "Thanks",
        text: "Your Application Submitted Successfully",
        icon: "success",
      });
      setTimeout(function () {
        //hide loader
        //show sent message.
        window.myIframe.remove();
        CreateForm();
      }, 2000);
    } else {
    }
  }
  function CreateForm() {
    const iframe = document.createElement("iframe");
    iframe.id = "subwindow";
    iframe.src = "./form.html";
    document.body.appendChild(iframe);
    window.myIframe = document.querySelector("#subwindow");
    window.myIframe.style.display = "none";
    console.log(window.myIframe);
  }

  useEffect(() => {
    CreateForm();
    file = document.getElementById("file-input");
    file.addEventListener("change", function () {
      let files = this.files;
      let dt = new DataTransfer();
      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        dt.items.add(new File([f.slice(0, f.size, f.type)], f.name));
      }
      console.log("new input");
      console.log(window.myIframe);
      console.log(
        window.myIframe.contentWindow.document.getElementById("myattachment")
      );
      window.myIframe.contentWindow.document.getElementById(
        "myattachment"
      ).files = dt.files;
    });
  }, []);
  return (
    <>
      <div className={styles.careerWrapper}>
        <img src={bgImg} alt="VRARMR_CAREER_PAGE_IMAGE" srcset="" />
        <div className={styles.textWrapper}>
          <h1>JOIN OUR TEAM</h1>
          <p>
            If you’re thinking of joining us, here are a few things you must
            know about us:
          </p>
          <ul>
            <li>We’re obsessed with what we do 😍</li>
            <li>
              So we work very hard (also because we just love what we do) 💪
            </li>
            <li>We move fast, learn and adapt 💨</li>
            <li>
              We wake up every morning knowing that we have a chance to do the
              best work of our lives here🚀
            </li>
            <li>
              We try our best to have fun along the way too (The magic is in
              working hard but also being playful) 🤫
            </li>
          </ul>
          <p>
            We’re looking for people who we can share all of the above with.
            We're all about teamwork.
          </p>
          <div className={styles.btnWrapper}>
            {/*   <form
              id="resumeForm"
              method="POST"
              //action="https://formsubmit.co/luckyyadav6264@gmail.com"
              //enctype="multipart/form-data"
              //onSubmit={preVDEf}
            > */}
            <label for="file-input">
              {fileNAme ? fileNAme : "Upload your resume here"}
            </label>
            <input
              id="file-input"
              name="attachment"
              className={styles.fileInput}
              type="file"
              accept=".pdf"
              onChange={getPdf}
            />
            <a href="#">
              <button
                id="aplyBtn"
                type="submit"
                onClick={callIframeFunction}
                className={`${styles.applybtn} btn-primary`}
              >
                APPLY NOW
              </button>
            </a>
          </div>
        </div>
        {/* <a href="#"> */}
          <button onClick={backToMain} className={styles.backBtn}>
            <RxCaretLeft className={styles.sideArrow} />
            BACK
          </button>
        {/* </a> */}
      </div>
    </>
  );
};

export default CmpCareer;
