import React, { useRef, useState, useEffect } from "react";
import { IoVolumeHighOutline, IoVolumeMuteOutline } from "react-icons/io5";
import cmpvideo from "../../images/Company_BLUE (2).mp4";
import cmpMobileVideo from "../../images/mobileVideo.mp4";
import TrackVisibility from "react-on-screen";

const Video = (props) => {
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(false);
  const [opacity, setOpacity] = React.useState(1);
  const [bgVsb, setbgVsb] = useState(false);

  let oldScrollY = window.scrollY;
  function handleScroll() {
    /*   if (window.innerWidth < 768) {
         setbgVsb(800);
       } else if (window.innerHeight > 768 && window.innerHeight < 1000) {
       } */
    /*   let vidoeTg = document.getElementsByClassName("vsbility")[0];
    console.log(vidoeTg);

    if (vidoeTg !== undefined) {
      console.log("nhi dikhega", vidoeTg);
      let videoReplBg = document.getElementsByClassName("video_Replace_Img")[0];
      videoReplBg.classList.remove("addLogoSecImage");
    } */
    let videoele = document.getElementById("cmpVid");
    if (oldScrollY < window.scrollY) {
      if (window.scrollY >= 40 && window.scrollY <= 79) {
        //console.log("hello");
        videoele.style.scale = 1.1;
      } else if (window.scrollY >= 80 && window.scrollY <= 119) {
        videoele.style.scale = 1.2;
      } else if (window.scrollY >= 120 && window.scrollY <= 149) {
        videoele.style.scale = 1.3;
      } else if (window.scrollY >= 150 && window.scrollY <= 179) {
        videoele.style.scale = 1.4;
      } else if (window.scrollY >= 180 && window.scrollY <= 199) {
        videoele.style.scale = 1.5;
      } else if (window.scrollY >= 200 && window.scrollY <= 249) {
        videoele.style.scale = 1.6;
      } else if (window.scrollY >= 250 && window.scrollY <= 349) {
        //console.log("hello");
        videoele.style.scale = 1.7;
      } else if (window.scrollY >= 350) {
        //console.log("hello");
        document.getElementById("muteIcon").style.display = "none";
      }
      if (window.scrollY <= 250) {
        document.getElementById("muteIcon").style.display = "flex";
        let getactivepage = window.location.href.split("/").pop();
        console.log(getactivepage);
        if (getactivepage == "#career") {
          console.log("hello my career");
          videoele.pause();
        } else {
          videoele.play();
        }
      }
      //console.log("hello down", window.scrollY);
    } else {
      //console.log("hello up");

      videoele.style.scale = 1;
    }
    const headerHeight = videoRef.current.innerHeight;
    const range = 200;
    const offset = headerHeight / 2;
    if (window.scrollY > window.innerHeight) {
      videoRef.current.pause();
    }
    if (window.scrollY > 2 * window.innerHeight) {
      setbgVsb(true);
      let whtasIcon = document.getElementById("whatsapp_link_icon");
      let scrooltoptopIcon = document.getElementById("scrollToTop");
      scrooltoptopIcon.style.display = "block";
      whtasIcon.style.display = "block";
    } else {
      let whtasIcon = document.getElementById("whatsapp_link_icon");
      whtasIcon.style.display = "none";
      let scrooltoptopIcon = document.getElementById("scrollToTop");
      scrooltoptopIcon.style.display = "none";
      /*   videoRef.current.play(); */
    }

    let calc = 1 - (window.scrollY - offset + range) / range;
    if (window.scrollY > window.innerHeight) {
      calc = 0;
    } else {
      calc = 1;
    }

    setOpacity(calc);
  }

  function muteAudio() {
    setMuted(!muted);
    let videoTG = document.getElementById("cmpVid");
    videoTG.setAttribute("muted", !muted);
  }
  useEffect(() => {
    setTimeout(() => {
      let videTg = document
        .getElementsByClassName("video")[0]
        .classList.remove("d-none");
    }, 1000);
    window.addEventListener("scroll", handleScroll);
    let landingPage1 = document.getElementById("root");
    //console.log(landingPage1.style.display);
    window.removeEventListener("scroll ", handleScroll);

    return () => {
      window.removeEventListener(" keydown", handleScroll);
    };
  }, [videoRef]);
  return (
    <div className="video-container" id="videoCnt">
      {window.innerWidth > 768 && (
        <TrackVisibility offset={window.innerHeight}>
          {({ isVisible }) => (
            <video
              //style={{ opacity: opacity }}
              id="cmpVid"
              ref={videoRef}
              src={cmpvideo}
              className={isVisible ? "video vsbility" : "video d-none"}
              playsInline
              preload="auto"
              loop
              muted={muted}
              onClick={() => setMuted(!muted)}
            />
          )}
        </TrackVisibility>
      )}
      {window.innerWidth < 768 && (
        <TrackVisibility offset={window.innerHeight}>
          {({ isVisible }) => (
            <video
              //style={{ opacity: opacity }}
              id="cmpVid"
              ref={videoRef}
              src={cmpMobileVideo}
              className={isVisible ? "video vsbility" : "video d-none"}
              playsInline
              preload="auto"
              loop
              muted={muted}
              onClick={() => setMuted(!muted)}
            />
          )}
        </TrackVisibility>
      )}
      <div id="muteIcon" className="mute_Icon_wrapper" onClick={muteAudio}>
        {muted ? (
          <IoVolumeMuteOutline style={{ color: "white" }} />
        ) : (
          <IoVolumeHighOutline style={{ color: "white" }} />
        )}
      </div>

      <div id="cntBG" className="video_Replace_Img">
        {/*   <img className="cntBG" id="cntBG" />  */}
      </div>
    </div>
  );
};

export default Video;
