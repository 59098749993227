import React, { useEffect } from "react";
import styles from "./Careercard.module.css";
import cardimage1 from "../../images/Packagecard1.png";

const Careercard = (props) => {
  useEffect(() => {
    if (props.setOpt) {
      props.setOpt();
    }
  }, [props.setOpt]);

  return (
    <>
      {window.innerWidth < 768 && (
        <div
          id={props.setId}
          onClick={props.check}
          className={`${styles.card} card`}
        >
          <img
            loading="lazy"
            src={
              props.setCurrentActiveCard === props.setId
                ? cardimage1
                : props.imgs
            }
            className="card-img-top"
            alt="..."
          />
          <h1 className={`${styles.overlayText} ${styles.addstroke}`}>
            {props.imgtext}
          </h1>
          <h1 className={styles.overlayText}>{props.imgtext}</h1>

          <div
            className={`${
              props.setCurrentActiveCard === props.setId
                ? `${styles.cardactive}`
                : `${styles.text}`
            }  ${props.classes} card-body`}
          >
            <p className="card-text ">{props.content}</p>
          </div>
        </div>
      )}
      {window.innerWidth > 768 && (
        <div
          style={{ cursor: "none" }}
          id={props.setId}
          onMouseEnter={props.check}
          onMouseLeave={props.leave}
          className={`${styles.card} card`}
        >
          <img
            loading="lazy"
            src={
              props.setCurrentActiveCard === props.setId
                ? cardimage1
                : props.imgs
            }
            className="card-img-top"
            alt="..."
          />
          <h1 className={`${styles.overlayText} ${styles.addstroke}`}>
            {props.imgtext}
          </h1>
          <h1 className={styles.overlayText}>{props.imgtext}</h1>

          <div
            className={`${
              props.setCurrentActiveCard === props.setId
                ? `${styles.cardactive}`
                : `${styles.text}`
            }  ${props.classes} card-body`}
          >
            <p className="card-text ">{props.content}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Careercard;
