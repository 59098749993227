import React from "react";
import Servicescard from "../../SmallComponent/ServicesCard/Servicescard";
import styles from "./Services.module.css";
import TrackVisibility from "react-on-screen";
import IsVisible from "react-is-visible";

const Services = (props) => {
  const contentList = [
    { id: 1, content: "Product Configurator" },
    { id: 2, content: "Virtual Catalog " },
    { id: 3, content: "Interactive Product Demonstration" },
    { id: 4, content: "Real Estate Explainer" },
    { id: 5, content: "Realistic Rendering" },
    { id: 6, content: "Web Games" },
    { id: 7, content: " Digital Sweepstake" },
    { id: 8, content: "Immersive Learning Modules" },
  ];

  const contentList1 = [
    { id: 1, content: " AR Printed Poster" },
    { id: 2, content: "AR Magazine /Newspaper Ads" },
    { id: 3, content: "AR Graffiti/ Art/ Paintings" },
    { id: 4, content: "AR Games" },
    { id: 5, content: "Snap AR Lens" },
    { id: 6, content: " Instagram AR Filters" },
    { id: 7, content: "AR Product Try-on" },
    { id: 8, content: "AR Mascot" },
    { id: 9, content: "AR Packaging Experience" },
  ];

  const contentList2 = [
    { id: 1, content: " Virtual Human" },
    { id: 2, content: "Avatar Generation" },
    { id: 3, content: " Interactive Quiz" },
    { id: 4, content: " NFT Creation and Marketplace" },
    { id: 5, content: "  Metaverse Application" },
    { id: 6, content: "Training Application" },
    { id: 7, content: "   Remote Maintenance Application" },
  ];

  const contentList3 = [
    { id: 1, content: "VR Educational Application" },
    { id: 2, content: " VR Games" },
    { id: 3, content: " VR Storytelling" },
  ];
  const contentList4 = [
    { id: 1, content: "Landmark Experience" },
    { id: 2, content: " Shopper Experience" },
    { id: 3, content: " Product Launch" },
  ];
  const contentList5 = [
    { id: 1, content: "Photo-Ops Kiosk" },
    { id: 2, content: "Entertainment Kiosk" },
  ];

  function reduceOpac() {
    let BG = document.getElementById("cntBG");
    if (BG) {
      BG.style.opacity = "0.5";
    }
    if (window.innerWidth < 768) {
      if (BG) {
        BG.classList.remove("addLogoSecImage");
        BG.classList.add("addBgColor");
      }
    } /* else {
      if (BG) {
        BG.classList.add("addLogoSecImage");
      }
    } */
  }
  /* useEffect(() => {
    props.remvBg();
  }, [props.remvBg]); */
  return (
    <>
      <div id="services" className={styles.service}>
        <IsVisible>
          {(isVisible) => (
            <div className={styles.pageheading}>
              <h1 className={isVisible ? `textAnim` : ""}>
                Post-covid customers started caring about values and emotions.
                With our specialty, we transform you to cater to the customers
                of today and tomorrow.
              </h1>
            </div>
          )}
        </IsVisible>
        <div className={styles.cardWrapper}>
          <div className={styles.firstRow}>
            <Servicescard
              list={contentList}
              head={"2D/3D"}
              classes={"firstcard"}
            />

            <TrackVisibility partialVisibility>
              {({ isVisible }) => (
                <Servicescard
                  setOpt={isVisible ? reduceOpac : ""}
                  list={contentList1}
                  head={"AUGMENTED REALITY"}
                  classes={"secondcard"}
                />
              )}
            </TrackVisibility>

            <Servicescard
              list={contentList2}
              head={"STANDALONE"}
              classes={"thirdcard"}
            />
          </div>
          <div className={styles.secondRow}>
            <Servicescard
              list={contentList3}
              head={"VIRTUAL REALITY"}
              classes={"fourthcard"}
            />

            <Servicescard
              list={contentList4}
              head={"PHYGITAL"}
              classes={"fivethcard"}
            />

            <Servicescard
              list={contentList5}
              head={"EVENTS"}
              classes={"sixthcard"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
