import React, { useEffect } from "react";

import styles from "./Card.module.css";
const Card = (props) => {
  useEffect(() => {
    if (props.changeBgHeight) {
      props.changeBgHeight();
    }
  }, [props.changeBgHeight]);

  return (
    <>
      <div className={styles.cardMain}>
        <div className={styles.cardmageWrapper}>
          <img src={props.logo} alt="VRARMR_CLIENT_BRAND_LOGO" />
        </div>
      </div>
    </>
  );
};

export default Card;
