import React, { useState, useRef, useEffect } from "react";
import styles from "./Formcard.module.css";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

const Formcard = (props) => {
  const form = useRef();
  const captcha = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [captc, setCaptc] = useState(false);
  const [DropIcon, setDropIcon] = useState(false);

  function restrictLetter(e) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
  }
  function changedropIcon() {
    setDropIcon(!DropIcon);
  }
  function changeIoconColor() {
    let dnmIcon = document.getElementById("downIcon");
    /*   if (dnmIcon) {
      dnmIcon.style.color = "#1755a3";
    } */
  }
  function changedropIconBlur() {
    setDropIcon(false);
  }
  function changePlaceText() {
    document
      .getElementById("inputPhoneNumber")
      .setAttribute("placeholder", "8995666999");
  }
  function changePlacetextAgain() {
    document
      .getElementById("inputPhoneNumber")
      .setAttribute("placeholder", "Phone Number");
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber && phoneNumber.match(/\d/g).length < 12) {
      toast.error("Invalid Phone Number");
    } else if (name && email && phoneNumber && options && message && captc) {
      document.getElementById("subBtn").setAttribute("disabled", "true");
      document.getElementById("loaderershh").style.display = "block";
      const data = {
        name: name,
        email: email,
        phone: phoneNumber,
        options: options,
        message: message,
      };

      const postData = fetch(
        "https://script.google.com/macros/s/AKfycbyUqTpxi5oA9T3gh0OehMTHS3HLO6ojTN38QOczRPUZjW13FuOOTltI7oAWQGkSb7f3fA/exec",
        {
          method: "POST",
          body: new FormData(form.current),
        }
      )
        .then((res) => {
          console.log("SUCCESSFULLY SUBMITTED");
          sendMail(e);
          document.getElementById("loaderershh").style.display = "none";
          document.getElementById("subBtn").innerText = "Message Sent";
          setTimeout(() => {
            setName("");
            setEmail("");
            setPhoneNumber("");
            setOptions("");
            setMessage("");
            document.getElementById("defaultOpt").setAttribute("selected", "");
            document.getElementById("subBtn").removeAttribute("disabled");
            document.getElementById("subBtn").innerText = "GET IN TOUCH";
            captcha.current.reset();
          }, 1000);
        })
        .catch((err) => console.log(err));
      console.log(postData);
      /*  toast.promise(
        postData,
        {
          loading: "Loading",
          success: <b>"Thank You,We will get you back"</b>,
          error: <b>Could not Sent.</b>,
        },
        {
          success: {
            style: {
              color: "#0339d9",
            },
          },
          /*  iconTheme: {
            primary: "#0339d9",
            secondary: "#fff",
          }, 
        },
        {
          style: {
            minWidth: "250px",
          },
          success: {
            duration: 1500000,
          },
        }
      ); */
    } else {
      if (options == "") {
        console.log("hello dear please choose options");
        toast.error("Please Select Your Purpose");
      }
      if (!captc) {
        toast.error("Fill The captcha");
      }
    }
  };
  function sendMail(e) {
    e.preventDefault();
    //axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post("https://formsubmit.co/ajax/contact@mrarvr.in", {
        name: "VRARMR",
        /* body: JSON.stringify(Object.fromEntries(new FormData(e.target))), */
        name: name,
        email: email,
        phone: phoneNumber,
        options: options,
        message: message,
        _subject: "VRARMR-Contact Us",
      })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  }
  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptc(true);
  }
  function nameOnChange(e) {
    setName(e.target.value);
    console.log(name);
  }
  useEffect(() => {
    console.log(name);
    let captch = document.getElementById("captcha");
    if (name != "") {
      captch.style.display = "block";
    } else {
      captch.style.display = "none";
    }
    if (props.setAnimbg) {
      props.setAnimbg();
    }
  }, [name, props.setAnimbg]);

  return (
    <div className={styles.formConatiner}>
      <form
        autoComplete="off"
        ref={form}
        onSubmit={handleSubmit}
        needs-validation="true"
        className=" position-relative"
      >
        <div className="mb-3">
          <input
            name="user_name"
            placeholder="Name"
            type="Name"
            value={name}
            onChange={nameOnChange}
            className={`${styles.NameInput} form-control`}
            id="inputName"
            required
          />
        </div>
        <div className="mb-3">
          <input
            name="user_email"
            placeholder="Email"
            type="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${styles.EmailInput} form-control`}
            id="inputEmail"
            required
          />
        </div>
        <div className="mb-3">
          {/*  <input
            
            type="tel"
            min="0"
            value={phone}
            id="inputPhoneNumber"
            pattern="[0-9]{10}"
            required
          /> */}
          <PhoneInput
            inputProps={{
              name: "user_phone",
              required: true,
              autoFocus: true,
              placeholder: "Phone Number",
            }}
            inputClass={`${styles.PhoneInput} inputLibraryclass form-control`}
            containerClass={`${styles.PhoneContainer} form-control `}
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
            className={`form-control`}
            //onInput={restrictLetter}
            country={"in"}
          />
        </div>
        <div className="mb-3 position-relative">
          <select
            id="selectTag"
            onChange={(e) => setOptions(e.target.value)}
            value={options}
            name="user_service_opt"
            className={`${styles.PurposeInput} form-control`}
            aria-label="Default select example"
            onClick={changedropIcon}
            onFocus={changeIoconColor}
            onBlur={changedropIconBlur}
            required
          >
            {/*    <option className={styles.optiontags} value="1">
              Purpose
            </option> */}
            <option id="defaultOpt" className={`${styles.optiontags} d-none`}>
              Purpose
            </option>
            <option className={styles.optiontags}>Project Brief</option>
            <option className={styles.optiontags}>Career</option>
            <option className={styles.optiontags}>Partner</option>
          </select>
          {!DropIcon && (
            <RxCaretDown
              id="downIcon"
              className={styles.dropdownicon}
              style={{ pointerEvents: "none" }}
            />
          )}
          {DropIcon && (
            <RxCaretUp
              id="upIcon"
              className={styles.dropdownicon}
              style={{ pointerEvents: "none", color: "#1755a3" }}
            />
          )}
        </div>
        <div className="mb-3 d-none">
          <input
            name="user_service"
            type="Message"
            placeholder="hello"
            value={options}
            className="form-control d-none"
            id="selectMessage"
          />
        </div>
        <div className="mb-3">
          <input
            name="message"
            placeholder="Your Message"
            type="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={`${styles.MessageInput} form-control`}
            id="inputMessage"
            required
          />
        </div>

        <ReCAPTCHA
          id="captcha"
          className="mb-3"
          style={{ display: "none" }}
          ref={captcha}
          sitekey="6LdqK94kAAAAACaJd4Zn8L105ii_xWcFnNdBowx-"
          onChange={onChange}
        />
        <div className="btn_wrapper_loader_wrapper">
          <button
            id="subBtn"
            type="submit"
            className={`${styles.coffee_btn} btn btn-primary`}
          >
            GET IN TOUCH
            <div id="loaderershh" class="submitBtnLoader"></div>
          </button>
        </div>
        <Toaster
          containerStyle={{
            position: "absolute",
            top: -30,
            right: 20,
          }}
        />
      </form>
    </div>
  );
};

export default Formcard;
