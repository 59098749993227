import React, { useEffect } from "react";
import Card from "../../../SmallComponent/ClientLogoCards/Card";
import styles from "./Cardpage.module.css";
import logo1 from "../../../images/LOGO/Banfield.png";
import logo2 from "../../../images/LOGO/chriss-consalting.png";
import logo3 from "../../../images/LOGO/funky buddha.png";
import logo4 from "../../../images/LOGO/ANMOL.png";
import logo5 from "../../../images/LOGO/Modelo.png";
import logo6 from "../../../images/LOGO/Papuk.png";
import logo7 from "../../../images/LOGO/one plus.png";
import logo8 from "../../../images/LOGO/khadi india.png";
import logo9 from "../../../images/LOGO/Walmart.png";
import logo10 from "../../../images/LOGO/Starbucks_.png";
import logo11 from "../../../images/LOGO/Lego.png";
import logo12 from "../../../images/LOGO/ITC.png";
import logo13 from "../../../images/LOGO/The Collective.png";
import logo14 from "../../../images/LOGO/nestle.png";
import logo15 from "../../../images/LOGO/Bingo.png";
import logo16 from "../../../images/LOGO/Pacifico.png";
import logo17 from "../../../images/LOGO/Neuro.png";
import logo18 from "../../../images/LOGO/maui.png";
import logo19 from "../../../images/LOGO/Remotely.png";
import logo20 from "../../../images/LOGO/DS group.png";
import logo21 from "../../../images/LOGO/Sunstone.png";
import logo22 from "../../../images/LOGO/OGX.png";
import logo23 from "../../../images/LOGO/Boston-Marathon.png";
import logo24 from "../../../images/LOGO/JIVA.png";
import logo25 from "../../../images/LOGO/Corona Master Brand.png";
import logo26 from "../../../images/LOGO/USHA_logo.png";
import logo27 from "../../../images/LOGO/Amazon_logo.png";
import logo28 from "../../../images/LOGO/Coca-Cola.png";
import logo29 from "../../../images/LOGO/Diablo.png";
import logo30 from "../../../images/LOGO/CyberPeace.png";
import logo31 from "../../../images/LOGO/sunrise logo copy.png";
import logo32 from "../../../images/LOGO/Magic Dine.png";
import logo33 from "../../../images/LOGO/marketing group_b.png";
import logo34 from "../../../images/LOGO/vertual-intergrated.png";
import logo35 from "../../../images/LOGO/Interact Ideas.png";
import logo36 from "../../../images/LOGO/D pod.png";
import logo37 from "../../../images/LOGO/Holland America.png";
import logo38 from "../../../images/LOGO/ZOAN.png";
import logo39 from "../../../images/LOGO/Zenith_Bank.png";
import logo40 from "../../../images/LOGO/WWE.png";
import logo41 from "../../../images/LOGO/TOI.png";
import logo42 from "../../../images/LOGO/Realwear.png";
import logo43 from "../../../images/LOGO/haviair.png";
import logo44 from "../../../images/LOGO/GharKar.png";
import logo45 from "../../../images/LOGO/raw-real-talks.png";
import logo46 from "../../../images/LOGO/the white room.png";
import logo47 from "../../../images/LOGO/birkhall asia.png";
import logo48 from "../../../images/LOGO/EXPRESSION INDIA.png";
import logo49 from "../../../images/LOGO/Mariama.png";
import logo50 from "../../../images/LOGO/Victoria.png";
import logoNewadd51 from "../../../images/LOGO/Aditya birla.png";
import allLogos from "../../../images/AllLogos.png";
import TrackVisibility from "react-on-screen";

const Cardpage = (props) => {
  /*  const hideLoader = () => {
    const getLoader = document.getElementById("loader");
    console.log(getLoader);
    getLoader.style.display = "none";
    document.getElementById("name_loader").style.display = "none";
    document.getElementById("spinner").style.display = "none";
    document.getElementById("landingPage").style.display = "block";
  }; */

  function smoothBgAnim() {
    let animBgPos = document.getElementsByClassName("bg")[0].style.position;
    let animBg = document.getElementsByClassName("bg");
    if (animBgPos == "absolute") {
      /* set angle/direction of linear gradient */
      animBg[0].classList.remove("bgsetaniamtion");
      animBg[1].classList.remove("bgsetaniamtion");
      animBg[2].classList.remove("bgsetaniamtion");

      /* set position */
      animBg[0].style.position = "fixed";
      animBg[1].style.position = "fixed";
      animBg[2].style.position = "fixed";
    } /*  else {
      animBg[0].style.position = "absolute";
      animBg[1].style.position = "absolute";
      animBg[2].style.position = "absolute";
    } */
  }
  useEffect(() => {
    props.setBg();
  }, [props.setBg]);

  return (
    <>
      <div className={`${styles.clientlogocard} conatiner`}>
        <div className={styles.bigImgWrapper}>
          <img src={allLogos} alt="VRARMR_clients..." />
        </div>
        <div className={`${styles.mobileview} row`}>
          <div className={`${styles.col_4}  col-4 col-md-3  mb-3`}>
            <Card logo={logo1} />
          </div>
          <div className={`${styles.col_4}  col-4 col-md-3  mb-3`}>
            <Card logo={logo2} />
          </div>
          <div className={`${styles.col_4}  col-4 col-md-3  mb-3`}>
            <Card logo={logo3} />
          </div>
          <div className={`${styles.col_4}  col-4 col-md-3 mb-3`}>
            <Card logo={logo4} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo5} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo6} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logoNewadd51} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo7} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo8} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo9} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo10} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo11} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo12} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo13} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo14} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo15} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo16} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo17} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo18} />
          </div>
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo19} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo20} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo21} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo22} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo23} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo24} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo25} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <TrackVisibility partialVisibility>
              {({ isVisible }) => (
                <Card
                  logo={logo26}
                  changeBgHeight={isVisible ? smoothBgAnim : ""}
                />
              )}
            </TrackVisibility>
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo27} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo28} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo29} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo30} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo31} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo32} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo33} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo34} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo35} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo36} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo37} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo38} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo39} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo40} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo41} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo42} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo43} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo44} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo45} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo46} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo47} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo48} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo49} />
          </div>{" "}
          <div className={`${styles.col_4} col-4 col-md-3 mb-3`}>
            <Card logo={logo50} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardpage;
